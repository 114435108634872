import {
  ArticleRounded,
  Close,
  Download,
  PictureAsPdfRounded,
} from '@mui/icons-material'
import {
  Box,
  IconButton,
  LinearProgress,
  Paper,
  Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { FileItem, FileStatus, getStatusColor } from '../../../types/types'

interface PdfListItemProps {
  fileItem: FileItem
  deleteFileHandler: (image: File) => void
}

const PdfListItem = React.memo((props: PdfListItemProps) => {
  const [objectUrl, setObjectUrl] = useState<string | null>(null)

  useEffect(() => {
    const url = URL.createObjectURL(props.fileItem.file)
    setObjectUrl(url)

    return () => {
      if (url) {
        URL.revokeObjectURL(url)
      }
    }
  }, [props.fileItem.file])

  const handleDownload = () => {
    if (objectUrl) {
      const link = document.createElement('a')
      link.href = objectUrl
      link.download = props.fileItem.file.name
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }

  return (
    <Paper
      sx={{
        width: '100%',
        height: 150,
        overflow: 'hidden',
      }}
    >
      {(props.fileItem.status === FileStatus.DOWNLOADING ||
        props.fileItem.status === FileStatus.PROCESSING ||
        props.fileItem.status === FileStatus.UPLOADING) && (
        <Box
          sx={{
            width: '100%',
            color: getStatusColor(props.fileItem.status),
          }}
        >
          <LinearProgress variant="indeterminate" color={'inherit'} />
        </Box>
      )}
      {(props.fileItem.status === FileStatus.DONE ||
        props.fileItem.status === FileStatus.OVERSIZE_ERROR ||
        props.fileItem.status === FileStatus.SERVER_ERROR ||
        props.fileItem.status === FileStatus.SELECTED) && (
        <Box
          sx={{
            width: '100%',
            color: getStatusColor(props.fileItem.status),
          }}
        >
          <LinearProgress variant="determinate" color={'inherit'} value={100} />
        </Box>
      )}

      <Box
        sx={{
          display: 'flex',
          backgroundColor: getStatusColor(props.fileItem.status),
          alignContent: 'center',
          alignItems: 'center',
          justifyContent: 'space-between',
          height: 40,
        }}
      >
        <Typography pl={1} variant="subtitle2" noWrap>
          {props.fileItem.status === FileStatus.SELECTED ||
          props.fileItem.status === FileStatus.DONE
            ? props.fileItem.file.name
            : props.fileItem.status}
        </Typography>
        {(props.fileItem.status === FileStatus.SELECTED ||
          props.fileItem.status === FileStatus.OVERSIZE_ERROR) && (
          <IconButton
            size="small"
            onClick={() => {
              props.deleteFileHandler(props.fileItem.file)
            }}
          >
            <Close />
          </IconButton>
        )}
        {props.fileItem.status === FileStatus.DONE && (
          <IconButton size="small" onClick={handleDownload}>
            <Download />
          </IconButton>
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: 100,
          color: getStatusColor(props.fileItem.status),
        }}
      >
        {props.fileItem.status === FileStatus.DONE ? (
          <ArticleRounded color="inherit" sx={{ fontSize: 80 }} />
        ) : (
          <PictureAsPdfRounded color="inherit" sx={{ fontSize: 80 }} />
        )}
      </Box>
    </Paper>
  )
})

export default PdfListItem
