import { Alert, Box, Button, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { RandomReveal } from 'react-random-reveal'
import { v4 as uuidv4 } from 'uuid'

const CoinFlip: React.FC = () => {
  const [result, setResult] = React.useState('')
  const [key, setKey] = React.useState(uuidv4())
  const flip = () => {
    const headsOrTails = Math.random() >= 0.5
    if (headsOrTails) {
      setResult('Heads')
    } else {
      setResult('Tails')
    }
    setKey(uuidv4())
  }

  useEffect(() => {
    flip()
  }, [])

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        flexDirection: 'column',
      }}
    >
      <Alert
        severity="info"
        icon={false}
        sx={{
          my: 2,
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
        }}
      >
        <Typography variant="h3">
          <RandomReveal
            isPlaying
            revealDuration={0}
            duration={1.5}
            characters={result}
            key={key}
          />
        </Typography>
      </Alert>
      <Button variant="outlined" sx={{ mt: 2, display: 'flex' }} onClick={flip}>
        Flip
      </Button>
    </Box>
  )
}

export default CoinFlip
