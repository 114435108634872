import { ContentCopy, Refresh } from '@mui/icons-material'
import { Button, Grid, Paper, Slider, Switch, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { RandomReveal } from 'react-random-reveal'

const PasswordGenerator = () => {
  function valuetext(value: number) {
    return `${value} Characters`
  }

  const [symbol, setSymbol] = React.useState(true)
  const [number, setNumber] = React.useState(true)
  const [length, setLength] = React.useState(8)
  const [password, setPassword] = React.useState('')

  const generatePassword = () => {
    let chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
    if (number) chars += '0123456789'
    if (symbol) chars += '!@#$%^&*()_+~`|}{[]:;?><,./-='
    let pass = ''
    for (let x = 0; x < length; x++) {
      const i = Math.floor(Math.random() * chars.length)
      pass += chars.charAt(i)
    }
    setPassword(pass)
  }

  useEffect(() => {
    generatePassword()
  }, [symbol, number, length])

  const handleCopy = () => {
    navigator.clipboard.writeText(password)
  }

  return (
    <Paper sx={{ p: 2 }}>
      <Grid container spacing={1}>
        <Grid item xs={4} sx={{ alignItems: 'center' }}>
          <Typography variant="body1" sx={{ textAlign: 'right' }} mr={1}>
            Password Length
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Slider
            aria-label="Always visible"
            value={length}
            min={6}
            max={100}
            getAriaValueText={valuetext}
            step={1}
            sx={{ width: '90%', ml: 1.5 }}
            valueLabelDisplay="on"
            onChange={(e, value) => setLength(value as number)}
          />
        </Grid>
        <Grid item xs={4} sx={{ alignItems: 'center' }}>
          <Typography variant="body1" sx={{ textAlign: 'right' }} mr={1}>
            Numbers
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Switch
            checked={number}
            onChange={(e) => setNumber(e.target.checked)}
          />
        </Grid>
        <Grid item xs={4} sx={{ alignItems: 'center' }}>
          <Typography variant="body1" sx={{ textAlign: 'right' }} mr={1}>
            Symbols
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Switch
            checked={symbol}
            onChange={(e) => setSymbol(e.target.checked)}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="h4"
            sx={{
              textAlign: 'center',
              overflowWrap: 'break-word',
              wordBreak: 'break-word',
            }}
          >
            <RandomReveal
              isPlaying
              duration={0.5}
              characters={password}
              key={password}
            />
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ textAlign: 'center' }}>
          <Button
            variant="outlined"
            size="small"
            onClick={handleCopy}
            startIcon={<ContentCopy />}
          >
            Copy
          </Button>
          <Button
            sx={{ ml: 2 }}
            variant="outlined"
            size="small"
            onClick={generatePassword}
            startIcon={<Refresh />}
          >
            Regenerate
          </Button>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default PasswordGenerator
