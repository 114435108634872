import { Close } from '@mui/icons-material'
import {
  Card,
  CardActionArea,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from '@mui/material'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import React from 'react'
import ReactGA4 from 'react-ga4'
import { useLocalStorage } from 'usehooks-ts'

interface FeaturedFuncProps {
  title: string
  description: string
  image: string
  imageText: string
  component?: React.ElementType
  size?: 'small' | 'medium' | 'large'
}

export default function FeaturedFunc(props: FeaturedFuncProps) {
  const [isOpen, setIsOpen] = React.useState(false)
  const [hasConsent] = useLocalStorage<boolean | undefined>(
    'cookieConsent',
    undefined,
  )

  const handleClose = () => {
    setIsOpen(false)
  }

  const handleOpen = () => {
    if (hasConsent) {
      ReactGA4.event({
        category: 'Function',
        action: 'OpenFunction',
        label: props.title,
      })
    }
    setIsOpen(true)
  }
  return (
    <>
      <Dialog
        fullWidth
        maxWidth={
          props.size === 'small' ? 'sm' : props.size === 'large' ? 'lg' : 'md'
        }
        open={isOpen}
      >
        <DialogTitle
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div>
            <Typography variant="h6"> {props.title}</Typography>
          </div>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{props.description}</DialogContentText>
          <Box sx={{ my: 2 }}>{props.component && <props.component />}</Box>
        </DialogContent>
      </Dialog>
      <Card
        sx={{
          position: 'relative',
          backgroundColor: 'grey.800',
          color: '#fff',
          mb: 2,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundImage: `url(${props.image})`,
        }}
      >
        {
          <img
            style={{ display: 'none' }}
            src={props.image}
            alt={props.imageText}
          />
        }
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            backgroundColor: 'rgba(0,0,0,.3)',
          }}
        />
        <CardActionArea
          onClick={() => {
            handleOpen()
          }}
        >
          <Grid container>
            <Grid item md={6}>
              <Box
                sx={{
                  position: 'relative',
                  p: { xs: 3, md: 6 },
                  pr: { md: 0 },
                }}
              >
                <Typography
                  component="h1"
                  variant="h3"
                  color="inherit"
                  gutterBottom
                >
                  {props.title}
                </Typography>
                <Typography variant="h5" color="inherit" paragraph>
                  {props.description}
                </Typography>
                {/* <Link variant="subtitle1" href="#">
              {func.linkText}
            </Link> */}
              </Box>
            </Grid>
          </Grid>
        </CardActionArea>
      </Card>
    </>
  )
}
