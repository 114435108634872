export enum FileStatus {
  SELECTED = 'Selected',
  UPLOADING = 'Uploading...',
  PROCESSING = 'Processing...',
  DOWNLOADING = 'Downloading...',
  DONE = 'Done',
  OVERSIZE_ERROR = 'File is too large.',
  SERVER_ERROR = 'Server error.',
}

export const getStatusColor = (status: FileStatus) => {
  switch (status) {
    case FileStatus.SELECTED:
      return 'primary.main'
    case FileStatus.UPLOADING:
      return 'info.main'
    case FileStatus.PROCESSING:
      return 'warning.main'
    case FileStatus.DOWNLOADING:
      return 'secondary.main'
    case FileStatus.DONE:
      return 'success.main'
    case FileStatus.OVERSIZE_ERROR:
    case FileStatus.SERVER_ERROR:
      return 'error.main'
    default:
      return 'text.primary'
  }
}
export enum FunctionName {
  BG_REMOVER = 'bgRemover',
  PDF2DOCX = 'pdf2docx',
  YOUTUBE_DOWNLOADER = 'youtubeDownloader',
  DUMMY_DATA_GENERATOR = 'dummyDataGenerator',
  COIN_FLIP = 'coinFlip',
  CLIPBOARD = 'clipboard',
}

interface Limit {
  maxSize?: number
  maxFiles?: number
  characterLimit?: number
  itemLimit?: number
}

let bgRemoverLimits: Limit = {
  maxSize: 5 * 1024 * 1024,
  maxFiles: 10,
}

let pdf2docxLimits: Limit = {
  maxSize: 10 * 1024 * 1024,
  maxFiles: 10,
}

let youtubeDownloaderLimits: Limit = {
  itemLimit: 5,
  characterLimit: 2048,
}

let dummyDataGeneratorLimits: Limit = {
  itemLimit: 100,
}

let coinFlipLimits: Limit = {}

let clipboardLimits: Limit = {
  characterLimit: 128000,
}

export const limits = {
  [FunctionName.BG_REMOVER]: bgRemoverLimits,
  [FunctionName.PDF2DOCX]: pdf2docxLimits,
  [FunctionName.YOUTUBE_DOWNLOADER]: youtubeDownloaderLimits,
  [FunctionName.DUMMY_DATA_GENERATOR]: dummyDataGeneratorLimits,
  [FunctionName.COIN_FLIP]: coinFlipLimits,
  [FunctionName.CLIPBOARD]: clipboardLimits,
}

export interface FileItem {
  file: File
  status: FileStatus
  outputFileUrl?: string
}
