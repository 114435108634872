import {
  AddCircleRounded,
  CloudDownload,
  CloudUpload,
  PlayCircleOutline,
} from '@mui/icons-material'
import { Box, Button, Card, Divider, Grid, styled } from '@mui/material'
import JSZip from 'jszip'
import React, { useEffect } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { v4 as uuidv4 } from 'uuid'
import { FileItem, FileStatus, FunctionName, limits } from '../../types/types'
import { getPresignedUrls, replaceFileExtension } from '../Utils'

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
})

interface Props {
  functionName: FunctionName
  acceptFiles: string
  actionButtonIcon?: React.ReactNode
  actionButtonTitle: string
  outputFileExtension?: string
  listItem: React.FC<{
    fileItem: FileItem
    deleteFileHandler: (file: File) => void
  }>
}

export default function GeneralUploadDownload(props: Props) {
  const [selectedFiles, setSelectedFiles] = React.useState<FileItem[]>([])
  const [isProcessing, setIsProcessing] = React.useState<boolean>(false)

  const maxFiles = limits[props.functionName].maxFiles || 0
  const maxSize = limits[props.functionName].maxSize || 0

  const isAllFilesDone = selectedFiles.every(
    (f) => f.status === FileStatus.DONE,
  )

  const isThereAnyError = selectedFiles.some(
    (f) => f.status === FileStatus.OVERSIZE_ERROR,
  )

  const deleteFileHandler = (f: File) => {
    setSelectedFiles((prevFiles) =>
      prevFiles.filter((prevFile) => prevFile.file !== f),
    )
  }

  const { executeRecaptcha } = useGoogleReCaptcha()

  useEffect(() => {
    if (isAllFilesDone) {
      setIsProcessing(false)
    }
  }, [isAllFilesDone])

  const handleDownloadAll = () => {
    if (isAllFilesDone) {
      const zip = new JSZip()

      selectedFiles.forEach((f) => {
        zip.file(f.file.name, f.file)
      })

      zip.generateAsync({ type: 'blob' }).then((blob) => {
        const url = URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.download = `omnifunc-${props.functionName}-${uuidv4()}.zip`
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        URL.revokeObjectURL(url)
      })
    }
  }

  const handleSelectedFiles = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      let allFiles = [
        ...selectedFiles,
        ...Array.from(event.target.files!).map((file) => ({
          file,
          status:
            file.size > maxSize
              ? FileStatus.OVERSIZE_ERROR
              : FileStatus.SELECTED,
        })),
      ]
      let uniqueFiles = allFiles.filter(
        (f, index, self) =>
          index === self.findIndex((t) => t.file.name === f.file.name),
      )
      setSelectedFiles(uniqueFiles.slice(0, maxFiles))
    }
  }

  const beginUploadHandler = async () => {
    setIsProcessing(true)
    selectedFiles
      .filter(
        (f) =>
          f.status === FileStatus.SELECTED ||
          f.status === FileStatus.SERVER_ERROR,
      )
      .map((f) =>
        setSelectedFiles((prevFiles) =>
          prevFiles.map((prevFile) =>
            prevFile.file === f.file
              ? {
                  ...prevFile,
                  status: FileStatus.UPLOADING,
                }
              : prevFile,
          ),
        ),
      )

    if (!executeRecaptcha) {
      return
    }
    const token = await executeRecaptcha(props.functionName)
    let presignedURLSData = await getPresignedUrls(
      token,
      props.functionName,
      selectedFiles
        .filter(
          (f) =>
            f.status === FileStatus.SELECTED ||
            f.status === FileStatus.SERVER_ERROR,
        )
        .map((f) => f.file),
    )

    if (!presignedURLSData?.presigned_urls) {
      setSelectedFiles((prevFiles) =>
        prevFiles.map((prevFile) =>
          prevFile.status === FileStatus.UPLOADING
            ? {
                ...prevFile,
                status: FileStatus.SERVER_ERROR,
              }
            : prevFile,
        ),
      )
      return
    }

    let presignedUrls = presignedURLSData.presigned_urls
    let reqId = presignedURLSData.req_id

    selectedFiles
      .filter(
        (f) =>
          f.status === FileStatus.SELECTED ||
          f.status === FileStatus.SERVER_ERROR,
      )
      .map((f, index) => {
        fetch(presignedUrls[index].url, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/octet-stream',
            'Content-Length': f.file.size.toString(),
          },
          body: f.file,
        }).then((res) => {
          if (res.ok) {
            setSelectedFiles((prevFiles) =>
              prevFiles.map((prevFile) =>
                prevFile.file === f.file
                  ? {
                      file: prevFile.file,
                      status: FileStatus.PROCESSING,
                      outputFileUrl: `https://${
                        process.env.REACT_APP_CDN_DOMAIN
                      }/${props.functionName}/${reqId}/${
                        props.outputFileExtension
                          ? replaceFileExtension(
                              prevFile.file.name,
                              props.outputFileExtension,
                            )
                          : prevFile.file.name
                      }`,
                    }
                  : prevFile,
              ),
            )
          }
        })
        return f
      })
  }

  useEffect(() => {
    const downloadFiles = async () => {
      const downloadFile = async (f: FileItem) => {
        if (f.outputFileUrl) {
          try {
            const fileResponse = await fetch(f.outputFileUrl)
            const blob = await fileResponse.blob()
            const downloadedFile = new File(
              [blob],
              props.outputFileExtension
                ? replaceFileExtension(f.file.name, props.outputFileExtension)
                : f.file.name,
            )

            setSelectedFiles((prevFiles) =>
              prevFiles.map((prevFile) =>
                prevFile.file === f.file
                  ? {
                      ...prevFile,
                      file: downloadedFile,
                      status: FileStatus.DONE,
                    }
                  : prevFile,
              ),
            )
          } catch (error) {
            console.error('Error downloading image:', error)
          }
        }
      }
      for (const f of selectedFiles) {
        if (f.status === FileStatus.DOWNLOADING) {
          await downloadFile(f)
        }
      }
    }
    downloadFiles()
  }, [selectedFiles, props.outputFileExtension])

  const checkIfFileIsReady = async (f: FileItem) => {
    if (f.outputFileUrl && f.status === FileStatus.PROCESSING) {
      const response = await fetch(f.outputFileUrl, {
        method: 'HEAD',
      })

      if (response.ok) {
        setSelectedFiles((prevFiles) =>
          prevFiles.map((prevFile) =>
            prevFile.file === f.file
              ? {
                  ...prevFile,
                  status: FileStatus.DOWNLOADING,
                }
              : prevFile,
          ),
        )
      }
    }
  }

  useEffect(() => {
    const intervalId = setInterval(async () => {
      for (const f of selectedFiles) {
        if (f.status === FileStatus.PROCESSING) {
          await checkIfFileIsReady(f)
        }
      }
    }, 3000)

    return () => clearInterval(intervalId)
  }, [selectedFiles])

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center',
          alignItems: 'center',
          mb: 2,
        }}
      >
        <Button
          component="label"
          variant="contained"
          startIcon={
            selectedFiles.length > 0 ? <AddCircleRounded /> : <CloudUpload />
          }
        >
          {selectedFiles.length > 0 ? 'Add More' : 'Select Files'}

          <VisuallyHiddenInput
            accept={props.acceptFiles}
            type="file"
            multiple
            onChange={handleSelectedFiles}
          />
        </Button>
        {selectedFiles.length > 0 && (
          <>
            <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />
            <Button
              disabled={isProcessing || isThereAnyError}
              variant="contained"
              onClick={() => {
                isAllFilesDone ? handleDownloadAll() : beginUploadHandler()
              }}
              startIcon={
                isAllFilesDone ? (
                  <CloudDownload />
                ) : props.actionButtonIcon ? (
                  props.actionButtonIcon
                ) : (
                  <PlayCircleOutline />
                )
              }
              color="secondary"
            >
              {isAllFilesDone ? 'Download All' : props.actionButtonTitle}
            </Button>
          </>
        )}
      </Box>
      {selectedFiles.length > 0 && (
        <Card sx={{ p: 2 }}>
          <Grid container spacing={2}>
            {selectedFiles.map((f) => (
              <Grid item xs={6} md={4} lg={3} key={f.file.name}>
                <props.listItem
                  fileItem={f}
                  deleteFileHandler={deleteFileHandler}
                />
              </Grid>
            ))}
          </Grid>
        </Card>
      )}
    </Box>
  )
}
