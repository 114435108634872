import {
  Box,
  Container,
  CssBaseline,
  Grid,
  ThemeProvider,
  Typography,
  createTheme,
} from '@mui/material'
import React, { useState } from 'react'
import ReactGA4 from 'react-ga4'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { useLocalStorage } from 'usehooks-ts'
import ClipboardHeader from './assets/headers/clipboard.png'
import DDGHeader from './assets/headers/ddg.png'
import FlipCoinHeader from './assets/headers/flipcoin.png'
import IBRHeader from './assets/headers/ibr.png'
import PDF2DOCXHeader from './assets/headers/pdftodocx.png'
import PasswordGeneratorHeader from './assets/headers/spg.png'
import YVDHeader from './assets/headers/yvd.png'
import CookieConsent from './components/CookieConsent'
import FeaturedFunc from './components/FeaturedFunc'
import Footer from './components/Footer'
import Func from './components/Func'
import BGRemover from './components/Funcs/BGRemover/BGRemover'
import Clipboard from './components/Funcs/Clipboard/Clipboard'
import CoinFlip from './components/Funcs/CoinFlip/CoinFlip'
import DummyDataGenerator from './components/Funcs/DummyDataGenerator/DummyDataGenerator'
import Pdf2Docx from './components/Funcs/PDF2DOCX/Pdf2Docx'
import PasswordGenerator from './components/Funcs/PasswordGenerator/PasswordGenerator'
import YoutubeDownloader from './components/Funcs/YoutubeDownloader/YoutubeDownloader'
import Header from './components/Header'

const logoFont = "'Shadows Into Light Two', cursive"
const font = "'Roboto', sans-serif"

export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#222222',
    },
  },

  typography: {
    fontFamily: font,
    h1: {
      fontFamily: logoFont,
      fontSize: '2rem',
    },
  },
})

export const lightTheme = createTheme({
  palette: {
    mode: 'light',
    background: {
      default: '#f2f3f4',
    },
  },

  typography: {
    fontFamily: font,
    h1: {
      fontFamily: logoFont,
      fontSize: '2rem',
    },
  },
})

function initializeGA4() {
  ReactGA4.initialize('G-93CHD8MEC0')
}

function App() {
  const [hasConsent] = useLocalStorage<boolean | undefined>(
    'cookieConsent',
    undefined,
  )
  React.useEffect(() => {
    console.log(hasConsent)
    if (hasConsent) {
      initializeGA4()
    }
  }, [hasConsent])

  const [favorites, setFavorites] = useLocalStorage<number[]>('favorites', [])
  const isFavorite = (id: number) => favorites.includes(id)

  const handleCookieAccept = () => {
    initializeGA4()
  }

  const handleCookieDecline = () => {}

  const functions = [
    {
      id: 2,
      title: 'Online PDF to DOCX Converter - Free and Fast Conversion',
      isFavorited: isFavorite(2),
      description:
        'Convert PDF to DOCX files effortlessly! Our advanced conversion technology ensures the highest quality output, matching the latest Microsoft Word standards.',
      image: PDF2DOCXHeader,
      imageText: 'PDF to DOCX Converter',
      component: Pdf2Docx,
    },
    {
      id: 3,
      title: 'YouTube Video Downloader - Download Videos in MP4 Format',
      isFavorited: isFavorite(3),
      description:
        'Download YouTube videos in MP4 format with the best quality available. Enjoy offline viewing anytime!',
      image: YVDHeader,
      imageText: 'YouTube Video Downloader',
      component: YoutubeDownloader,
    },
    {
      id: 5,
      title: 'Strong Password Generator - Create Secure Passwords Instantly',
      isFavorited: isFavorite(5),
      description:
        'Generate a strong and secure password with our easy-to-use password generator. Enhance your online security!',
      image: PasswordGeneratorHeader,
      imageText: 'Strong Password Generator',
      component: PasswordGenerator,
    },
    {
      id: 6,
      title: 'Dummy Data Generator - Create Realistic Test Data Easily',
      isFavorited: isFavorite(6),
      description:
        'Generate realistic dummy data instantly. Choose fields and row count to get your data in JSON, CSV, or XLSX format for testing and development purposes.',
      image: DDGHeader,
      imageText: 'Dummy Data Generator',
      component: DummyDataGenerator,
    },
    {
      id: 7,
      title: 'Clipboard - Secure Text Sharing with Reveal Code',
      isFavorited: isFavorite(7),
      description:
        'Paste text and generate a reveal code for secure sharing. Share the code with friends for text revelation. Optionally, set a password for added protection.',
      image: ClipboardHeader,
      imageText: 'Clipboard',
      component: Clipboard,
    },
    {
      id: 8,
      title: 'Flip a Coin - Heads or Tails? Get a Random Result',
      isFavorited: isFavorite(8),
      description:
        'Make decisions fun and random! Flip a coin and get an instant result. Heads or tails, let chance decide.',
      image: FlipCoinHeader,
      imageText: 'Flip a Coin',
      component: CoinFlip,
    },
  ]
  const featuredFunc = {
    title: 'Automatic Background Removal from Images - Quick and Easy',
    description:
      'Effortlessly remove the background from any image with our 100% automatic tool. Get professional results in just a few seconds with a single click.',
    image: IBRHeader,
    imageText: 'Remove Background from Images',
    component: BGRemover,
  }

  const [light] = useLocalStorage<boolean>('lightTheme', true)

  const [funcs, setFuncs] = useState(functions)
  const [isSearching, setIsSearching] = useState<boolean>(false)

  const handleFavorite = (id: number) => {
    console.log(id)
    const newFavorites = favorites.includes(id)
      ? favorites.filter((favorite) => favorite !== id)
      : [...favorites, id]
    setFavorites(newFavorites)
    const newFuncs = funcs
      .map((func) => {
        if (func.id === id) {
          func.isFavorited = !func.isFavorited
        }
        return func
      })
      .sort((a, b) => {
        return a.isFavorited === b.isFavorited ? 0 : a.isFavorited ? -1 : 1
      })

    setFuncs(newFuncs)
  }

  const searchHandler = (searchText: string) => {
    const filteredFuncs = functions.filter((func) =>
      func.title.toLowerCase().includes(searchText.toLowerCase()),
    )
    const sortedFuncs = filteredFuncs.sort((a, b) => {
      return a.isFavorited === b.isFavorited ? 0 : a.isFavorited ? -1 : 1
    })
    if (searchText === '') {
      setIsSearching(false)
    } else {
      setIsSearching(true)
    }
    setFuncs(sortedFuncs)
  }

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey="6Ldtx0UpAAAAANFk8SbCTXTT09z2IkHF_ad-zBSA"
      scriptProps={{
        async: false,
        defer: false,
        appendTo: 'head',
        nonce: undefined,
      }}
    >
      <ThemeProvider theme={light ? lightTheme : darkTheme}>
        <HelmetProvider>
          <CssBaseline enableColorScheme />
          <Container maxWidth="lg">
            <Helmet>
              <title>OmniFunc - Powerful Tools for Everyday Tasks</title>
              <meta
                name="description"
                content="Explore a collection of powerful online tools for everyday tasks. Convert PDF to DOCX, download YouTube videos, generate strong passwords, and more!"
              />
              <meta
                name="keywords"
                content="PDF to DOCX, YouTube Video Downloader, Password Generator, Dummy Data Generator, Clipboard, Pastebin, Flip a Coin, Online Tools"
              />
              <meta name="author" content="OmniFunc Inc." />
              <meta name="robots" content="index, follow" />
            </Helmet>
            <Header searchHandler={searchHandler} />
            <main>
              {funcs.length === 0 ? (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Typography variant="h4">No functions found.</Typography>
                </Box>
              ) : (
                <>
                  <Box
                    sx={{
                      display: isSearching ? 'none' : 'block',
                    }}
                  >
                    <FeaturedFunc {...featuredFunc} />
                  </Box>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <Grid container spacing={2}>
                        {funcs.map((func, index) => (
                          <React.Fragment key={func.id}>
                            {index % 2 === 0 && (
                              <Grid item xs={12} key={func.id}>
                                <Func
                                  {...func}
                                  favoriteHandler={handleFavorite}
                                />
                              </Grid>
                            )}
                          </React.Fragment>
                        ))}
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Grid container spacing={2}>
                        {funcs.map((func, index) => (
                          <React.Fragment key={func.id}>
                            {index % 2 === 1 && (
                              <Grid item xs={12} key={func.id}>
                                <Func
                                  {...func}
                                  favoriteHandler={handleFavorite}
                                />
                              </Grid>
                            )}
                          </React.Fragment>
                        ))}
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}
            </main>
            <CookieConsent
              onAccept={handleCookieAccept}
              onDecline={handleCookieDecline}
            />
            <Footer description="Made with love." />
          </Container>
        </HelmetProvider>
      </ThemeProvider>
    </GoogleReCaptchaProvider>
  )
}

export default App
