import { FunctionName } from '../../../types/types'
import YoutubeDownloader from '../../GeneralUploadDownload/GeneralUploadDownload'
import ImageListItem from './ImageListItem'

export default function BGRemover() {
  return (
    <YoutubeDownloader
      functionName={FunctionName.BG_REMOVER}
      actionButtonTitle="Remove Backgrounds"
      outputFileExtension="png"
      acceptFiles="image/*"
      listItem={ImageListItem}
    />
  )
}
