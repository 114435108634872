import { Close, Share, Star, StarBorder } from '@mui/icons-material'
import {
  Box,
  CardActionArea,
  CardActions,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from '@mui/material'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Typography from '@mui/material/Typography'
import React from 'react'
import ReactGA4 from 'react-ga4'
import { useLocalStorage } from 'usehooks-ts'

interface FuncProps {
  id: number
  title: string
  description: string
  image: string
  imageText: string
  isFavorited: boolean
  component?: React.ElementType
  size?: 'small' | 'medium' | 'large'
  favoriteHandler: (id: number) => void
}

export default function Func(props: FuncProps) {
  const [isOpen, setIsOpen] = React.useState(false)
  const [hasConsent] = useLocalStorage<boolean | undefined>(
    'cookieConsent',
    undefined,
  )

  const handleClose = () => {
    setIsOpen(false)
  }

  const handleOpen = () => {
    console.log(hasConsent)
    if (hasConsent) {
      ReactGA4.event({
        category: 'Function',
        action: 'OpenFunction',
        label: props.title,
      })
    }
    setIsOpen(true)
  }

  return (
    <Box>
      <Dialog
        fullWidth
        maxWidth={
          props.size === 'small' ? 'sm' : props.size === 'large' ? 'lg' : 'md'
        }
        open={isOpen}
      >
        <DialogTitle
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div>
            <Typography variant="h6"> {props.title}</Typography>
          </div>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{props.description}</DialogContentText>
          <Box sx={{ my: 2 }}>{props.component && <props.component />}</Box>
        </DialogContent>
      </Dialog>
      <Card>
        <CardActionArea
          id={`function_${props.title.replace(/\s/g, '')}`}
          onClick={() => {
            handleOpen()
          }}
        >
          <CardMedia
            component="img"
            sx={{ height: 160 }}
            image={props.image}
            alt={props.imageText}
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              {props.title}
            </Typography>
            <Typography variant="body2">{props.description}</Typography>
          </CardContent>
        </CardActionArea>
        <CardActions>
          <IconButton
            aria-label="add to favorites"
            onClick={() => props.favoriteHandler(props.id)}
          >
            {props.isFavorited ? <Star /> : <StarBorder />}
          </IconButton>
          <IconButton aria-label="share">
            <Share />
          </IconButton>
        </CardActions>
      </Card>
    </Box>
  )
}
