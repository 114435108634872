import { Close, DarkMode, LightMode, Search } from '@mui/icons-material'
import {
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { useState } from 'react'
import { useLocalStorage } from 'usehooks-ts'

interface HeaderProps {
  searchHandler: (searchText: string) => void
}

export default function Header(props: HeaderProps) {
  const [light, setLight] = useLocalStorage<boolean>('lightTheme', true)
  const isSmallerThanMd = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md'),
  )
  const [searchText, setSearchText] = useState<string>('')

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        my: 2,
        p: 2,
        height: '80px',
        borderRadius: '35px',
      }}
    >
      <Typography variant="h1">OmniFunc</Typography>
      <TextField
        fullWidth
        size="medium"
        sx={{
          '& fieldset': { border: 'none' },
          mx: {
            xs: 1,
            md: 5,
          },
        }}
        id="outlined-basic"
        placeholder={isSmallerThanMd ? 'Search...' : 'Search for a function...'}
        variant="outlined"
        value={searchText}
        onChange={(e) => {
          setSearchText(e.target.value)
          props.searchHandler(e.target.value)
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),

          endAdornment: searchText !== '' && (
            <InputAdornment position="end">
              <IconButton
                value="check"
                onClick={() => {
                  setSearchText('')
                }}
              >
                <Close />
              </IconButton>
            </InputAdornment>
          ),

          sx: {
            backgroundColor: light ? '#ffffff' : '#333333',
            borderRadius: '50px',
          },
        }}
      />
      <Box>
        <IconButton
          value="check"
          onClick={() => {
            setLight(!light)
          }}
        >
          {light ? <DarkMode /> : <LightMode />}
        </IconButton>
      </Box>
    </Box>
  )
}
