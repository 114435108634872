import { FunctionName } from '../../../types/types'
import GeneralUploadDownload from '../../GeneralUploadDownload/GeneralUploadDownload'
import PdfListItem from './PdfListItem'

export default function Pdf2Docx() {
  return (
    <GeneralUploadDownload
      functionName={FunctionName.PDF2DOCX}
      acceptFiles=".pdf"
      actionButtonTitle="Convert to DOCX"
      outputFileExtension="docx"
      listItem={PdfListItem}
    />
  )
}
