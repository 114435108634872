import { TableView } from '@mui/icons-material'
import LoadingButton from '@mui/lab/LoadingButton'
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
} from '@mui/material'
import React, { useEffect } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { v4 as uuidv4 } from 'uuid'
import { FunctionName, limits } from '../../../types/types'
import { getPresignedUrls } from '../../Utils'

const DummyDataGenerator = () => {
  const [selectedFields, setSelectedFields] = React.useState([''])
  const [format, setFormat] = React.useState('csv')
  const [rowCount, setRowCount] = React.useState(10)
  const [isLoading, setIsLoading] = React.useState(false)
  const [outputUrl, setOutputUrl] = React.useState('')
  const { executeRecaptcha } = useGoogleReCaptcha()

  const checkIfDataReady = async () => {
    const response = await fetch(outputUrl, {
      method: 'HEAD',
    })

    if (response.ok) {
      const link = document.createElement('a')
      link.href = outputUrl
      link.setAttribute('download', '')
      document.body.appendChild(link)
      link.click()
      link.remove()
      setOutputUrl('')
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (outputUrl === '') return
    const intervalId = setInterval(async () => {
      await checkIfDataReady()
    }, 3000)

    return () => clearInterval(intervalId)
  }, [outputUrl])

  const handleSubmit = async () => {
    if (selectedFields.filter((field) => field !== '').length === 0) return

    setIsLoading(true)
    const json = JSON.stringify({
      selectedFields: selectedFields.filter((field) => field !== ''),
      format: format,
      rowCount: rowCount,
    })

    const blob = new Blob([json], {
      type: 'application/json',
    })

    let fileName = uuidv4()

    const file = new File([blob], `${fileName}.json`, {
      type: 'application/json',
    })

    if (!executeRecaptcha) {
      return
    }
    const token = await executeRecaptcha('dummyDataGenerator')

    const presignedURLSData = await getPresignedUrls(
      token,
      'dummyDataGenerator',
      [file],
    )

    if (!presignedURLSData?.presigned_urls || !presignedURLSData?.req_id) {
      setIsLoading(false)
      return
    }

    const presignedUrls = presignedURLSData.presigned_urls
    const presignedURL = presignedUrls[0].url

    await fetch(presignedURL, {
      method: 'PUT',
      body: file,
      headers: {
        'Content-Type': 'application/octet-stream',
        'Content-Length': file.size.toString(),
      },
    })
      .then((res) => {})
      .catch((err) => {
        console.log(err)
        setIsLoading(false)
        return
      })

    setOutputUrl(
      `https://${process.env.REACT_APP_CDN_DOMAIN}/${FunctionName.DUMMY_DATA_GENERATOR}/${presignedURLSData.req_id}/${fileName}.${format}`,
    )
  }

  return (
    <Paper sx={{ p: 2 }}>
      <Grid container spacing={1}>
        <Grid item xs={6} md={4}>
          <FormControlLabel
            control={
              <Checkbox
                onClick={() => {
                  if (selectedFields.includes('uuid4')) {
                    setSelectedFields(
                      selectedFields.filter((field) => field !== 'uuid4'),
                    )
                  } else {
                    setSelectedFields([...selectedFields, 'uuid4'])
                  }
                }}
              />
            }
            label="UUID"
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <FormControlLabel
            control={
              <Checkbox
                onClick={() => {
                  if (selectedFields.includes('first_name')) {
                    setSelectedFields(
                      selectedFields.filter((field) => field !== 'first_name'),
                    )
                  } else {
                    setSelectedFields([...selectedFields, 'first_name'])
                  }
                }}
              />
            }
            label="First Name"
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <FormControlLabel
            control={
              <Checkbox
                onClick={() => {
                  if (selectedFields.includes('last_name')) {
                    setSelectedFields(
                      selectedFields.filter((field) => field !== 'last_name'),
                    )
                  } else {
                    setSelectedFields([...selectedFields, 'last_name'])
                  }
                }}
              />
            }
            label="Last Name"
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <FormControlLabel
            control={
              <Checkbox
                onClick={() => {
                  if (selectedFields.includes('email')) {
                    setSelectedFields(
                      selectedFields.filter((field) => field !== 'email'),
                    )
                  } else {
                    setSelectedFields([...selectedFields, 'email'])
                  }
                }}
              />
            }
            label="E-Mail"
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <FormControlLabel
            control={
              <Checkbox
                onClick={() => {
                  if (selectedFields.includes('date_of_birth')) {
                    setSelectedFields(
                      selectedFields.filter(
                        (field) => field !== 'date_of_birth',
                      ),
                    )
                  } else {
                    setSelectedFields([...selectedFields, 'date_of_birth'])
                  }
                }}
              />
            }
            label="Birth Date"
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <FormControlLabel
            control={
              <Checkbox
                onClick={() => {
                  if (selectedFields.includes('phone_number')) {
                    setSelectedFields(
                      selectedFields.filter(
                        (field) => field !== 'phone_number',
                      ),
                    )
                  } else {
                    setSelectedFields([...selectedFields, 'phone_number'])
                  }
                }}
              />
            }
            label="Phone Number"
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <FormControlLabel
            control={
              <Checkbox
                onClick={() => {
                  if (selectedFields.includes('user_name')) {
                    setSelectedFields(
                      selectedFields.filter((field) => field !== 'user_name'),
                    )
                  } else {
                    setSelectedFields([...selectedFields, 'user_name'])
                  }
                }}
              />
            }
            label="Username"
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <FormControlLabel
            control={
              <Checkbox
                onClick={() => {
                  if (selectedFields.includes('password')) {
                    setSelectedFields(
                      selectedFields.filter((field) => field !== 'password'),
                    )
                  } else {
                    setSelectedFields([...selectedFields, 'password'])
                  }
                }}
              />
            }
            label="Password"
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <FormControlLabel
            control={
              <Checkbox
                onClick={() => {
                  if (selectedFields.includes('address')) {
                    setSelectedFields(
                      selectedFields.filter((field) => field !== 'address'),
                    )
                  } else {
                    setSelectedFields([...selectedFields, 'address'])
                  }
                }}
              />
            }
            label="Address"
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <FormControlLabel
            control={
              <Checkbox
                onClick={() => {
                  if (selectedFields.includes('postalcode')) {
                    setSelectedFields(
                      selectedFields.filter((field) => field !== 'postalcode'),
                    )
                  } else {
                    setSelectedFields([...selectedFields, 'postalcode'])
                  }
                }}
              />
            }
            label="Postal Code"
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <FormControlLabel
            control={
              <Checkbox
                onClick={() => {
                  if (selectedFields.includes('city')) {
                    setSelectedFields(
                      selectedFields.filter((field) => field !== 'city'),
                    )
                  } else {
                    setSelectedFields([...selectedFields, 'city'])
                  }
                }}
              />
            }
            label="City"
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <FormControlLabel
            control={
              <Checkbox
                onClick={() => {
                  if (selectedFields.includes('country')) {
                    setSelectedFields(
                      selectedFields.filter((field) => field !== 'country'),
                    )
                  } else {
                    setSelectedFields([...selectedFields, 'country'])
                  }
                }}
              />
            }
            label="Country"
          />
        </Grid>
        <Grid item xs={6} sx={{ mt: 2 }}>
          <FormControl fullWidth>
            <InputLabel id="format-label">Format</InputLabel>
            <Select
              labelId="format-label"
              id="format"
              value={format}
              label="Format"
              onChange={(e) => setFormat(e.target.value as string)}
            >
              <MenuItem value={'csv'}>CSV</MenuItem>
              <MenuItem value={'xlsx'}>XLSX</MenuItem>
              <MenuItem value={'json'}>JSON</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} sx={{ mt: 2 }}>
          <TextField
            fullWidth
            id="outlined-number"
            label="Number of rows"
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            value={rowCount}
            onChange={(e) => {
              if (
                parseInt(e.target.value) >
                limits[FunctionName.DUMMY_DATA_GENERATOR].itemLimit!
              ) {
                setRowCount(
                  limits[FunctionName.DUMMY_DATA_GENERATOR].itemLimit!,
                )
                return
              }
              setRowCount(parseInt(e.target.value))
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              mt: 2,
            }}
          >
            <LoadingButton
              loading={isLoading}
              loadingPosition="start"
              startIcon={<TableView />}
              variant="outlined"
              onClick={handleSubmit}
            >
              {isLoading ? 'Generating' : 'Generate'}
            </LoadingButton>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default DummyDataGenerator
