import {
  ContentCopy,
  ContentPaste,
  RemoveRedEye,
  Visibility,
  VisibilityOff,
} from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  OutlinedInput,
  Paper,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material'
import * as CryptoJS from 'crypto-js'
import React, { useEffect, useState } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { v4 as uuidv4 } from 'uuid'
import { FunctionName, limits } from '../../../types/types'
import { getPresignedUrls } from '../../Utils'

const isEncrypted = (text: string) => {
  return text.startsWith('U2FsdGVkX1')
}

const Clipboard = () => {
  const [isLoading, setIsLoading] = React.useState(false)

  const [isPasswordProtected, setIsPasswordProtected] = useState(false)
  const [showPassword, setShowPassword] = useState(false)

  const [password, setPassword] = useState('')
  const [text, setText] = useState('')
  const [revealCode, setRevealCode] = useState('')

  const [getTextCode, setGetTextCode] = useState('')
  const [revealedText, setRevealedText] = useState('')
  const [isRevealedTextEncrypted, setIsRevealedTextEncrypted] = useState(false)
  const [showRevealPassword, setShowRevealPassword] = useState(false)
  const [revealPassword, setRevealPassword] = useState('')

  const [notFound, setNotFound] = useState(false)
  const [wrongPassword, setWrongPassword] = useState(false)
  const { executeRecaptcha } = useGoogleReCaptcha()

  const resetForm = () => {
    setText('')
    setIsPasswordProtected(false)
    setPassword('')
    setRevealCode('')
    setGetTextCode('')
    setRevealedText('')
    setIsRevealedTextEncrypted(false)
    setRevealPassword('')
    setNotFound(false)
    setWrongPassword(false)
  }

  const revealText = async () => {
    if (!getTextCode) {
      return
    }
    setIsLoading(true)
    setNotFound(false)
    await fetch(
      `https://${process.env.REACT_APP_CDN_DOMAIN}/clipboard/${getTextCode}.txt`,
    )
      .then((res) => {
        if (res.status === 404) {
          setNotFound(true)
          return
        }

        return res.text()
      })
      .then((data) => {
        if (!data) {
          setNotFound(true)
          return
        }
        if (isEncrypted(data)) {
          setIsRevealedTextEncrypted(true)
          setRevealPassword('')
          setPasswordFormOpen(true)
        }
        setRevealedText(data)
      })
      .catch((err) => {
        console.log(err)
        setNotFound(true)
        return
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const handleSubmit = async () => {
    if ((isPasswordProtected && password === '') || isLoading || !text) {
      return
    }

    setIsLoading(true)
    let blob: Blob | null = null

    let fileName = uuidv4()

    if (isPasswordProtected) {
      const encryptedText = CryptoJS.AES.encrypt(text, password).toString()

      blob = new Blob([encryptedText], {
        type: 'text/plain;charset=utf-8',
      })
    } else {
      blob = new Blob([text], {
        type: 'text/plain;charset=utf-8',
      })
    }

    const file = new File([blob], `${fileName}.txt`, {
      type: 'text/plain;charset=utf-8',
    })

    if (!executeRecaptcha) {
      return
    }
    const token = await executeRecaptcha('clipboard')

    const presignedURLSData = await getPresignedUrls(token, 'clipboard', [file])
    if (!presignedURLSData?.presigned_url || !presignedURLSData?.req_id) {
      setIsLoading(false)
      return
    }

    const presignedUrl = presignedURLSData.presigned_url
    const reqId = presignedURLSData.req_id

    await fetch(presignedUrl, {
      method: 'PUT',
      body: file,
      headers: {
        'Content-Type': 'text/plain;charset=utf-8',
        'Content-Length': file.size.toString(),
      },
    })
      .then((res) => {
        if (res.status === 200) {
          setRevealCode(reqId)
        }
        setIsLoading(false)
      })
      .catch((err) => {
        console.log(err)
        return
      })
      .finally(() => {
        setText('')
        setIsPasswordProtected(false)
        setPassword('')
        setIsLoading(false)
      })
  }

  const handleClickShowPassword = () => setShowPassword((show) => !show)
  const handleClickShowRevealPassword = () =>
    setShowRevealPassword((show) => !show)

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault()
  }

  const [tabValue, setTabValue] = React.useState(0)

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    resetForm()
    setTabValue(newValue)
  }

  const [open, setOpen] = React.useState(false)

  const handleClose = () => {
    setOpen(false)

    setRevealCode('')
  }

  useEffect(() => {
    if (revealCode) {
      setOpen(true)
    }
  }, [revealCode])

  const [passwordFormOpen, setPasswordFormOpen] = React.useState(false)

  const handleShowEncrypted = () => {
    setIsRevealedTextEncrypted(false)
    setPasswordFormOpen(false)
  }

  const handleDecrypt = () => {
    if (!revealPassword) {
      return
    }
    setWrongPassword(false)
    const decryptedText = CryptoJS.AES.decrypt(
      revealedText,
      revealPassword,
    ).toString(CryptoJS.enc.Utf8)
    if (!decryptedText) {
      setWrongPassword(true)
      return
    }
    setRevealedText(decryptedText)
    setIsRevealedTextEncrypted(false)
    setPasswordFormOpen(false)
  }

  const handlePasswordFormClose = () => {
    setPasswordFormOpen(false)
  }

  return (
    <Paper sx={{ p: 2 }}>
      <Dialog open={passwordFormOpen} onClose={handlePasswordFormClose}>
        <DialogTitle>Enter password</DialogTitle>
        <DialogContent>
          <DialogContentText mb={2}>
            It seems like the text is encrypted. Please enter the password to
            decrypt.
          </DialogContentText>
          {wrongPassword && (
            <Alert severity="error" sx={{ mb: 3 }}>
              The password is incorrect.
            </Alert>
          )}
          <FormControl fullWidth>
            <InputLabel htmlFor="reveal-password">Password</InputLabel>
            <OutlinedInput
              fullWidth
              id="reveal-password"
              label="Password"
              onChange={(e) => {
                setWrongPassword(false)
                setRevealPassword(e.target.value)
              }}
              type={showRevealPassword ? 'text' : 'password'}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowRevealPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showRevealPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleShowEncrypted}>Show Encrypted</Button>
          <Button onClick={handleDecrypt}>Decrypt</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Reveal Code</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please note down the reveal code. You will need it to reveal the
            text.
          </DialogContentText>

          <Alert
            severity="success"
            icon={false}
            sx={{
              mt: 2,
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            <Typography variant="h3">
              {revealCode}
              <IconButton
                sx={{ ml: 1 }}
                onClick={() => navigator.clipboard.writeText(revealCode)}
              >
                <ContentCopy />
              </IconButton>
            </Typography>
          </Alert>
          <Divider sx={{ my: 2 }} orientation="horizontal" flexItem>
            <Typography variant="caption">or</Typography>
          </Divider>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              flexDirection: 'row',
            }}
          >
            <Link
              variant="body2"
              href={`https://${process.env.REACT_APP_CDN_DOMAIN}/clipboard/${revealCode}.txt`}
              target="_blank"
            >
              {`https://${process.env.REACT_APP_CDN_DOMAIN}/clipboard/${revealCode}.txt`}
            </Link>
            <IconButton
              sx={{
                ml: 1,
              }}
              size="small"
              onClick={() =>
                navigator.clipboard.writeText(
                  `https://${process.env.REACT_APP_CDN_DOMAIN}/clipboard/${revealCode}.txt`,
                )
              }
            >
              <ContentCopy sx={{ fontSize: '14px' }} />
            </IconButton>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Grid container alignItems="center" spacing={1}>
        <Grid item xs={12}>
          <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                aria-label="basic tabs example"
              >
                <Tab label="Paste Text" />
                <Tab label="Reveal" />
              </Tabs>
            </Box>
          </Box>
        </Grid>
        {tabValue === 0 && (
          <>
            <Grid item xs={12} my={2}>
              <Alert
                severity="info"
                sx={{
                  mb: 2,
                }}
              >
                Once you paste you will be given with a <b>reveal code</b> which
                is a simple english word.
              </Alert>
              <Alert severity="warning">
                For enhanced privacy protection, the text will be promptly
                deleted from our server within a <b>24-hour period</b>.
              </Alert>
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="standard-multiline-static"
                label="Paste your text here"
                fullWidth
                multiline
                rows={6}
                variant="outlined"
                onChange={(e) => {
                  setText(
                    e.target.value.slice(
                      0,
                      limits[FunctionName.CLIPBOARD].characterLimit,
                    ),
                  )
                }}
                value={text}
              />
            </Grid>

            <Grid item xs={12}>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox checked={isPasswordProtected} />}
                  label="Protect with password"
                  onChange={() => setIsPasswordProtected(!isPasswordProtected)}
                />
              </FormGroup>
            </Grid>
            {isPasswordProtected && (
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel htmlFor="password">Password</InputLabel>
                  <OutlinedInput
                    fullWidth
                    id="password"
                    label="Password"
                    onChange={(e) => setPassword(e.target.value)}
                    type={showPassword ? 'text' : 'password'}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>
            )}
            <Grid item xs={12} sx={{ textAlign: 'center' }}>
              <LoadingButton
                loading={isLoading}
                variant="contained"
                startIcon={<ContentPaste />}
                sx={{ mt: 1 }}
                onClick={handleSubmit}
              >
                Paste
              </LoadingButton>
            </Grid>
          </>
        )}
        {tabValue === 1 && (
          <>
            <Grid item xs={12} my={1}>
              <Alert severity="info">
                Enter your reveal code. If the text is password protected, you
                will be asked to enter the password.
              </Alert>
            </Grid>
            {notFound && (
              <Grid item xs={12} mb={2}>
                <Alert severity="error">
                  The reveal code is either invalid or the text has been
                  deleted.
                </Alert>
              </Grid>
            )}

            <Grid item xs={12}>
              <TextField
                fullWidth
                id="outlined-basic"
                label="Write your reveal code here"
                variant="outlined"
                onChange={(e) => setGetTextCode(e.target.value)}
                value={getTextCode}
              />
            </Grid>

            {revealedText && !isRevealedTextEncrypted && (
              <Grid item xs={12}>
                <TextField
                  id="standard-multiline-static"
                  fullWidth
                  multiline
                  rows={6}
                  variant="outlined"
                  value={revealedText}
                  disabled
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() =>
                            navigator.clipboard.writeText(revealedText)
                          }
                        >
                          <ContentCopy />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            )}
            <Grid item xs={12} sx={{ textAlign: 'center' }}>
              <LoadingButton
                variant="contained"
                startIcon={<RemoveRedEye />}
                sx={{ mt: 1 }}
                onClick={revealText}
                loading={isLoading}
              >
                Reveal
              </LoadingButton>
            </Grid>
          </>
        )}
      </Grid>
    </Paper>
  )
}

export default Clipboard
