import { Add, Close, Download, Refresh, YouTube } from '@mui/icons-material'
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  OutlinedInput,
  Typography,
} from '@mui/material'
import React, { useEffect } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { v4 as uuidv4 } from 'uuid'
import { FileStatus, FunctionName, limits } from '../../../types/types'
import { getPresignedUrls } from '../../Utils'

type YoutubeItem = {
  url?: string
  outputUrl?: string
  status?: FileStatus
  formError?: boolean
}

const YoutubeDownloader = () => {
  const CHARACTER_LIMIT =
    limits[FunctionName.YOUTUBE_DOWNLOADER].characterLimit!
  const MAX_URLS_ALLOWED = limits[FunctionName.YOUTUBE_DOWNLOADER].itemLimit!

  const [isProcessing, setIsProcessing] = React.useState(false)
  const [youtubeItems, setYoutubeItems] = React.useState<YoutubeItem[]>([
    { url: '' },
  ])

  const isAllFinished = youtubeItems.every(
    (yi) => yi.status !== FileStatus.PROCESSING,
  )

  useEffect(() => {
    if (isAllFinished) {
      setIsProcessing(false)
    }
  }, [isAllFinished])

  const youtubeRegex = /(?:v=|\/)([0-9A-Za-z_-]{11}).*/

  const { executeRecaptcha } = useGoogleReCaptcha()

  function isYouTubeVideoId(str: string): boolean {
    return youtubeRegex.test(str)
  }

  const resetForm = () => {
    setYoutubeItems([{ url: '' }])
  }

  const removeYoutubeUrl = (index: number) => () => {
    setYoutubeItems((prevItems) => prevItems.filter((_, i) => i !== index))
  }

  const addYoutubeUrl = () => {
    if (youtubeItems.length >= MAX_URLS_ALLOWED) {
      return
    }
    setYoutubeItems((prevItems) => [...prevItems, {}])
  }

  const checkIfVideoReady = async (yi: YoutubeItem) => {
    setYoutubeItems((prevItems) =>
      prevItems.map((prevItem) =>
        prevItem.url === yi.url
          ? {
              ...prevItem,
            }
          : prevItem,
      ),
    )

    if (!yi.outputUrl) {
      return
    }

    const response = await fetch(yi.outputUrl, {
      method: 'HEAD',
    })

    if (response.ok) {
      setYoutubeItems((prevItems) =>
        prevItems.map((prevItem) =>
          prevItem.url === yi.url
            ? {
                ...prevItem,
                status: FileStatus.DONE,
              }
            : prevItem,
        ),
      )
    }
  }

  useEffect(() => {
    const intervalId = setInterval(async () => {
      for (const yi of youtubeItems) {
        if (yi.status === FileStatus.PROCESSING) {
          await checkIfVideoReady(yi)
        }
      }
    }, 3000)

    return () => clearInterval(intervalId)
  }, [youtubeItems])

  const handleSubmit = () => {
    //Set all retry counts to 0
    setYoutubeItems((prevItems) =>
      prevItems.map((prevItem) => ({
        ...prevItem,
        retryCount: 0,
      })),
    )
    uploadYoutubeVideosFile()
  }

  const uploadYoutubeVideosFile = async () => {
    let isThereAnyError = false
    youtubeItems.map((yi, index) => {
      if (!yi.url || !isYouTubeVideoId(yi.url)) {
        isThereAnyError = true
        setYoutubeItems((prevItems) =>
          prevItems.map((prevItem, i) =>
            i === index
              ? {
                  ...prevItem,
                  formError: true,
                }
              : prevItem,
          ),
        )
      }
      return yi
    })
    if (isThereAnyError) {
      return
    }

    youtubeItems.map((yi, index) => {
      setYoutubeItems((prevItems) =>
        prevItems.map((prevItem, i) =>
          i === index
            ? {
                ...prevItem,
                status: FileStatus.SELECTED,
              }
            : prevItem,
        ),
      )
      return yi
    })

    setIsProcessing(true)
    const json = JSON.stringify({
      youtubeUrls: [...youtubeItems.map((yi) => yi.url)],
    })

    const blob = new Blob([json], {
      type: 'application/json',
    })

    let fileName = uuidv4()

    const file = new File([blob], `${fileName}.json`, {
      type: 'application/json',
    })

    if (!executeRecaptcha) {
      return
    }
    const token = await executeRecaptcha('clipboard')

    const presignedURLSData = await getPresignedUrls(
      token,
      'youtubeDownloader',
      [file],
    )

    if (!presignedURLSData?.presigned_urls || !presignedURLSData?.req_id) {
      setIsProcessing(false)
      return
    }

    const presignedUrls = presignedURLSData.presigned_urls
    const presignedURL = presignedUrls[0].url

    await fetch(presignedURL, {
      method: 'PUT',
      body: file,
      headers: {
        'Content-Type': 'application/octet-stream',
        'Content-Length': file.size.toString(),
      },
    })
      .then((res) => {})
      .catch((err) => {
        console.log(err)
        setIsProcessing(false)
        return
      })

    youtubeItems.map((yi, index) => {
      setYoutubeItems((prevItems) =>
        prevItems.map((prevItem, i) =>
          i === index
            ? {
                ...prevItem,
                status: FileStatus.PROCESSING,
                outputUrl: `https://${process.env.REACT_APP_CDN_DOMAIN}/${FunctionName.YOUTUBE_DOWNLOADER}/${presignedURLSData.req_id}/${index}_${fileName}.mp4`,
              }
            : prevItem,
        ),
      )
      return yi
    })
  }

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          mb: 2,
          width: '100%',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        <Button
          sx={{ display: 'flex', alignSelf: 'flex-end' }}
          size="small"
          onClick={resetForm}
          startIcon={<Refresh />}
          disabled={isProcessing}
        >
          Reset Form
        </Button>
        {youtubeItems.map((yi, index) => (
          <Box display={'flex'} flexDirection={'row'} gap={2} key={uuidv4()}>
            <FormControl fullWidth>
              <InputLabel htmlFor={`youtube-video-url-${index}`}>
                Youtube Video URL
              </InputLabel>
              <OutlinedInput
                id={`youtube-video-url-${index}`}
                disabled={isProcessing}
                error={yi.formError || yi.status === FileStatus.SERVER_ERROR}
                key={index}
                label="Youtube Video URL"
                inputProps={{
                  maxLength: CHARACTER_LIMIT,
                }}
                fullWidth
                value={yi.url}
                onChange={(e) => {
                  setYoutubeItems((prevItems) =>
                    prevItems.map((prevItem, i) =>
                      i === index
                        ? {
                            ...prevItem,
                            url: e.target.value,
                            formError: false,
                            status: undefined,
                          }
                        : prevItem,
                    ),
                  )
                }}
                endAdornment={
                  yi.status === FileStatus.PROCESSING ||
                  yi.status === FileStatus.SELECTED ? (
                    <Chip
                      label={
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            gap: 1,
                          }}
                        >
                          <CircularProgress size={15} />
                          {yi.status === FileStatus.SELECTED
                            ? 'Preparing...'
                            : 'Processing...'}
                        </Box>
                      }
                    />
                  ) : yi.status === FileStatus.DONE ? (
                    <Chip
                      clickable
                      color="success"
                      onClick={() => {
                        const link = document.createElement('a')
                        link.href = yi.outputUrl!
                        link.download = ''
                        document.body.appendChild(link)
                        link.click()
                        document.body.removeChild(link)
                      }}
                      label={
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            gap: 0.5,
                          }}
                        >
                          <Download sx={{ fontSize: 18 }} />
                          <Typography variant="body2">Download</Typography>
                        </Box>
                      }
                    />
                  ) : (
                    youtubeItems.length > 1 && (
                      <>
                        <IconButton
                          disabled={isProcessing}
                          onClick={removeYoutubeUrl(index)}
                          color="primary"
                          size="small"
                        >
                          <Close />
                        </IconButton>
                      </>
                    )
                  )
                }
              />
            </FormControl>
          </Box>
        ))}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Button
            size="small"
            onClick={addYoutubeUrl}
            startIcon={<Add />}
            disabled={isProcessing || youtubeItems.length === MAX_URLS_ALLOWED}
          >
            Add More
          </Button>
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Button
          disabled={isProcessing}
          sx={{ mt: 2 }}
          variant="contained"
          color="primary"
          startIcon={<YouTube />}
          onClick={handleSubmit}
        >
          Start Converting
        </Button>
      </Box>
    </Box>
  )
}

export default YoutubeDownloader
