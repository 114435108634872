// CookieConsent.tsx

import { Button, Snackbar } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useLocalStorage } from 'usehooks-ts'

interface CookieConsentProps {
  onAccept: () => void
  onDecline: () => void
}

const CookieConsent: React.FC<CookieConsentProps> = ({
  onAccept,
  onDecline,
}) => {
  const [open, setOpen] = useState<boolean>(false)
  const [hasConsent, setHasConsent] = useLocalStorage<boolean | undefined>(
    'cookieConsent',
    undefined,
  )

  useEffect(() => {
    if (hasConsent === undefined) {
      setOpen(true)
    }
  }, [hasConsent])

  const handleAccept = () => {
    setHasConsent(true)
    setOpen(false)
    onAccept()
  }

  const handleDecline = () => {
    setHasConsent(false)
    setOpen(false)
    onDecline()
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={open}
      message="We use cookies to enhance your browsing experience, serve personalized ads or content, and analyze our website traffic."
      action={
        <>
          <Button color="primary" size="small" onClick={handleDecline}>
            Decline
          </Button>
          <Button color="primary" size="small" onClick={handleAccept}>
            Accept
          </Button>
        </>
      }
    />
  )
}

export default CookieConsent
