type GetPresignedUrls = (
  recaptchaToken: string,
  functionName: string,
  files: File[],
) => Promise<any>
export const getPresignedUrls: GetPresignedUrls = async (
  recaptchaToken,
  functionName,
  files,
) => {
  let presignedURLSData = await fetch(
    process.env.REACT_APP_API_URL + '/presignUrls',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Recaptcha-Response': recaptchaToken,
      },
      body: JSON.stringify({
        function: functionName,
        files: files.map((f) => ({
          name: f.name,
          size: f.size,
        })),
      }),
    },
  )
    .then((res) => res.json())
    .catch((err) => {
      return
    })

  return presignedURLSData
}

export function replaceFileExtension(
  path: string,
  newExtension: string,
): string {
  const [basePath] = path.split('.')
  return `${basePath}.${newExtension}`
}
