import { Close, Download } from '@mui/icons-material'
import {
  Box,
  IconButton,
  LinearProgress,
  Paper,
  Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { PhotoProvider, PhotoView } from 'react-photo-view'
import 'react-photo-view/dist/react-photo-view.css'
import { FileItem, FileStatus, getStatusColor } from '../../../types/types'

interface ImageListItemProps {
  fileItem: FileItem
  deleteFileHandler: (image: File) => void
}

const ImageListItem = React.memo((props: ImageListItemProps) => {
  const [objectUrl, setObjectUrl] = useState<string | null>(null)

  useEffect(() => {
    const url = URL.createObjectURL(props.fileItem.file)
    setObjectUrl(url)

    return () => {
      if (url) {
        URL.revokeObjectURL(url)
      }
    }
  }, [props.fileItem.file])

  const handleDownload = () => {
    if (objectUrl) {
      const link = document.createElement('a')
      link.href = objectUrl
      link.download = props.fileItem.file.name
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }

  return (
    <Paper
      sx={{
        width: '100%',
        height: 200,
        overflow: 'hidden',
      }}
    >
      {(props.fileItem.status === FileStatus.DOWNLOADING ||
        props.fileItem.status === FileStatus.PROCESSING ||
        props.fileItem.status === FileStatus.UPLOADING) && (
        <Box
          sx={{
            width: '100%',
            color: getStatusColor(props.fileItem.status),
          }}
        >
          <LinearProgress variant="indeterminate" color={'inherit'} />
        </Box>
      )}
      {(props.fileItem.status === FileStatus.DONE ||
        props.fileItem.status === FileStatus.OVERSIZE_ERROR ||
        props.fileItem.status === FileStatus.SERVER_ERROR ||
        props.fileItem.status === FileStatus.SELECTED) && (
        <Box
          sx={{
            width: '100%',
            color: getStatusColor(props.fileItem.status),
          }}
        >
          <LinearProgress variant="determinate" color={'inherit'} value={100} />
        </Box>
      )}

      <Box
        sx={{
          display: 'flex',
          backgroundColor: getStatusColor(props.fileItem.status),
          alignContent: 'center',
          alignItems: 'center',
          justifyContent: 'space-between',
          height: 40,
        }}
      >
        <Typography pl={1} variant="subtitle2" color={'white'} noWrap>
          {props.fileItem.status === FileStatus.SELECTED ||
          props.fileItem.status === FileStatus.DONE
            ? props.fileItem.file.name
            : props.fileItem.status}
        </Typography>
        {(props.fileItem.status === FileStatus.SELECTED ||
          props.fileItem.status === FileStatus.SERVER_ERROR ||
          props.fileItem.status === FileStatus.OVERSIZE_ERROR) && (
          <IconButton
            size="small"
            onClick={() => {
              props.deleteFileHandler(props.fileItem.file)
            }}
            sx={{
              color: 'white',
            }}
          >
            <Close />
          </IconButton>
        )}
        {props.fileItem.status === FileStatus.DONE && (
          <IconButton
            size="small"
            onClick={handleDownload}
            sx={{ color: 'white' }}
          >
            <Download />
          </IconButton>
        )}
      </Box>
      <PhotoProvider bannerVisible={false}>
        <PhotoView src={objectUrl!}>
          <img
            height={156}
            width={'100%'}
            style={{
              objectFit: 'cover',
            }}
            src={objectUrl!}
            alt={props.fileItem.file.name}
          />
        </PhotoView>
      </PhotoProvider>
    </Paper>
  )
})

export default ImageListItem
