import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Typography,
} from '@mui/material'
import React, { useState } from 'react'

const TermsAndPrivacyDialog: React.FC = () => {
  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Typography variant="body2" color="text.secondary" align="center">
        <Link color="inherit" href="#" onClick={handleOpen}>
          Terms of Service and Privacy Policy
        </Link>
      </Typography>

      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>Terms of Service and Privacy Policy</DialogTitle>
        <DialogContent>
          <Typography variant="body2">
            Welcome to OmniFunc! By using the OmniFunc web app ("the Service"),
            you agree to comply with and be bound by the following terms and
            conditions of use. Please read these terms carefully before using
            our Service.
          </Typography>

          <Typography variant="body2" mt={2}>
            <b>1. Acceptance of Terms:</b> By accessing or using the Service,
            you agree to these Terms of Service. If you do not agree with any
            part of these terms, you may not use the Service.
          </Typography>

          <Typography variant="body2" mt={2}>
            <b>2. Disclaimer of Liability:</b> OmniFunc, its creators, and
            affiliates shall not be responsible or liable for any direct,
            indirect, incidental, consequential, special, or exemplary damages,
            including but not limited to, damages for loss of profits, goodwill,
            use, data, or other intangible losses, resulting from: - The use or
            inability to use the Service. - Unauthorized access to or use of our
            servers. - Any third-party content or conduct.
          </Typography>

          <Typography variant="body2" mt={2}>
            <b>3. Privacy Policy:</b> OmniFunc respects your privacy and is
            committed to protecting it. Our Privacy Policy outlines how we
            collect, use, disclose, and manage your personal information. By
            using the Service, you consent to the practices described in our
            Privacy Policy.
          </Typography>

          <Typography variant="body2" mt={2}>
            <b>4. File Uploads:</b> For functions that involve file uploads,
            please be aware that all files uploaded to our servers are handled
            with the utmost care. We do not claim ownership of any uploaded
            files, and they are deleted from our servers within 24 hours of
            creation.
          </Typography>

          <Typography variant="body2" mt={2}>
            <b>5. User Data:</b> Any user-related data stored on our end is
            treated with strict confidentiality. We do not share, sell, or
            disclose any user information to third parties. Additionally, all
            user-related data such as clipboard function's texts, is
            automatically deleted within 24 hours after its creation. Please
            note that data collected for analytics purposes, such as Google
            Analytics, may not be subject to the same deletion process.
            Analytics data is used to improve the overall performance and user
            experience of our service but is not associated with individual user
            identities.
          </Typography>

          <Typography variant="body2" mt={2}>
            <b>6. Changes to Terms and Privacy Policy:</b> OmniFunc reserves the
            right to modify these Terms of Service and Privacy Policy at any
            time. We encourage you to review these terms periodically for any
            changes.
          </Typography>

          <Typography variant="body2" mt={3}>
            <b>Disclaimer for YouTube Video Downloader:</b> Our YouTube Video
            Downloader feature is designed to provide users with the ability to
            download YouTube videos for offline viewing. However, users are
            solely responsible for ensuring that their use of this feature
            complies with all applicable copyright laws and the terms of service
            of YouTube. Please be aware of the following:
            <br />
            <b>1. Copyrighted Content:</b> Downloading copyrighted material
            without permission may violate copyright laws. Users should obtain
            the necessary rights or permissions before downloading and
            distributing any content.
            <br />
            <b>2. Terms of Service:</b> Users are expected to adhere to
            YouTube's terms of service when using our YouTube Video Downloader.
            Any violation of YouTube's policies is the user's responsibility,
            and we disclaim any legal liability for such violations.
            <br />
            <b>3. Fair Use:</b>Users are encouraged to consider the principles
            of fair use when downloading and using content. Fair use allows for
            certain uses of copyrighted material without permission, such as for
            criticism, commentary, news reporting, teaching, scholarship, or
            research.
            <br /> <br />
            By using the YouTube Video Downloader feature, users acknowledge and
            accept that they are solely responsible for the content they
            download and the manner in which they use it. Our service disclaims
            any legal responsibility for the actions of users that may infringe
            upon the rights of copyright holders or violate any applicable laws.
            <br /> <br />
            Please use this feature responsibly and in accordance with all
            relevant legal and ethical standards.
          </Typography>

          <Typography variant="body2" mt={2}>
            <b>7. Contact Information:</b> If you have any questions or concerns
            about these terms or our privacy practices, please contact us at{' '}
            <Link href="mailto:contact@omnifunc.com">contact@omnifunc.com</Link>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default TermsAndPrivacyDialog
