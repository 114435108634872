import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import TermsAndPrivacyDialog from './TermsAndPrivacy'

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://omnifunc.com/">
        OmniFunc
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  )
}

interface FooterProps {
  description: string
}

export default function Footer(props: FooterProps) {
  const { description } = props

  return (
    <Box component="footer" sx={{ py: 6 }}>
      <Container maxWidth="lg">
        <Typography
          variant="subtitle1"
          align="center"
          color="text.secondary"
          component="p"
        >
          {description}
        </Typography>
        <TermsAndPrivacyDialog />
        <Copyright />
      </Container>
    </Box>
  )
}
